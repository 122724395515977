<template>
  <div
    class="c-input-psw l-half-box"
    :class="{
      showPsw: isShow,
      hasErr: errMsg,
    }"
  >
    <input
      :type="inputType"
      :placeholder="placeholder"
      :value="value"
      :name="name"
      v-model="inputedVal"
      @input="$emit('update:value', $event.target.value)"
    />
    <span class="c-input-psw__icon" @click="showHandler">
      {{ this.isShow ? "非表示" : "表示" }}
    </span>
    <span v-if="validation" class="c-inputTooltip">
      {{ tooltipMsg }}
    </span>
    <p v-if="errMsg" class="c-input__errMsg">{{ errMsg }}</p>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    name: {
      type: String,
      default: "password",
    },
    placeholder: {
      type: String,
      default: "パスワード",
    },
    tooltip: {
      type: String,
    },
    validation: {
      type: Function,
    },
    errMsg: {
      type: String,
    },
  },
  data() {
    return {
      inputedVal: this.value,
      isValid: false,
      isShow: false,
    };
  },
  watch: {
    inputedVal() {
      this.validationSet();
    },
  },
  computed: {
    inputType() {
      return this.isShow ? "text" : "password";
    },
    tooltipMsg() {
      return this.isValid ? "OK" : this.tooltip;
    },
  },
  methods: {
    showHandler() {
      this.isShow = !this.isShow;
    },
    changeHandler() {
      this.isValid = this.validation(this.inputedVal);
    },
    validationSet() {
      this.changeHandler();
      this.$emit("update:isValid", this.isValid);
      if (this.isValid) {
        this.$emit("update:errMsg", "");
      }
    },
  },
  mounted() {
    this.validationSet();
  },
};
</script>
