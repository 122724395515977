<template>
  <div class="l-wrapper">
    <AppHeader />
    <main class="l-main -login">
      <div class="l-lead">
        <h1 class="c-lead">パスワード設定</h1>
      </div>
      <div class="l-contents-white l-box">
        <ErrorMessage :errMsgs="errMsgs" />
        <p class="l-block">
          メール認証に成功しました。<br />
          初回ログインのためのパスワードを設定してください。
        </p>
        <form>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>パスワード</label>
            </div>
            <div class="c-inputWrap__items">
              <div>
                <InputPassword
                  :value.sync="form.password"
                  tooltip="英字と数字を組み合わせて8文字以上で入力してください。"
                  :validation="validatePsw"
                  :isValid.sync="isValidOf.password"
                />
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>パスワード（再入力）</label>
            </div>
            <div class="c-inputWrap__items">
              <div>
                <InputPassword
                  :value.sync="form.passwordConfirm"
                  tooltip="英字と数字を組み合わせて8文字以上で入力してください。"
                  :validation="validatePsw"
                  :isValid.sync="isValidOf.passwordConfirm"
                />
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label"></div>
            <div class="c-inputWrap__items">
              <div class="c-checkbox l-half-box">
                <input
                  id="agree-terms"
                  type="checkbox"
                  v-model="form.agree_terms"
                />
                <label class="c-checkbox__label" for="agree-terms">
                  <span class="c-checkbox__box"></span>
                  <a href="/v_resources/assets/pdf/利用規約.pdf" target="_blank">利用規約</a>
                  に同意する
                </label>
              </div>
              <div class="c-checkbox">
                <input
                  id="agree-polycy"
                  type="checkbox"
                  v-model="form.agree_policy"
                />
                <label class="c-checkbox__label" for="agree-polycy">
                  <span class="c-checkbox__box"></span>
                  <router-link to="//www.cba-japan.co.jp/privacy/" target="_blank">
                    プライバシーポリシー
                  </router-link>
                  に同意する
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- /.l-contents-white -->
      <div>
        <div class="c-btn primary mid center l-half-box" @click="submit">
          送信
        </div>
      </div>
    </main>

    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import InputPassword from "@/components/parts/input/InputPassword";
import validation from "@/mixin/validation";
import { API_URL } from "@/const";
axios.defaults.headers.common["Content-Type"] = "application/json";

export default {
  name: "password-setting",
  components: {
    InputPassword,
  },
  mixins: [validation],
  data() {
    return {
      form: {
        password: "",
        passwordConfirm: "",
        agree_terms: false,
        agree_policy: false,
      },
      token: null,
      errMsgs: [],
      isValidOf: {
        password: false,
        passwordConfirm: false,
      },
    };
  },
  methods: {
    isValidInput() {
      const f = this.form;
      return (
        f.password === f.passwordConfirm && f.agree_terms && f.agree_policy
      );
    },
    postInitSettingApi() {
      axios
        .post(API_URL.AUTH.INIT_SETTING, {
          token: this.token,
          password: this.form.password,
        })
        .then((res) => {
          console.log(res);
        })
        .then(() => {
          this.$router.push("/login");
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
        });
    },
    async submit() {
      this.errMsgs = [];
      if (!this.isValidOf.password || !this.isValidOf.passwordConfirm) {
        this.errMsgs.push("英字と数字を組み合わせて8文字以上で入力してください。");
      }
      if (this.isValidOf.password && this.isValidOf.passwordConfirm) {
        if (this.form.password !== this.form.passwordConfirm) {
          this.errMsgs.push("パスワードとパスワード(再入力)と異なります。");
        } else {
          if (!this.form.agree_terms) {
            this.errMsgs.push("利用規約に同意してください。");
          }
          if (!this.form.agree_policy) {
            this.errMsgs.push("プライバシーポリシーに同意してください。");
          }
          if (this.form.agree_terms && this.form.agree_policy) {
            await this.postInitSettingApi();
          }
        }
      }
    },
  },
  mounted() {
    // public系の画面はログアウトさせる
    this.$store.commit("auth/set", false);
    this.$store.commit("userInfo/set", {});

    this.token = this.$route.query.token;
  },
};
</script>
